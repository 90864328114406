<template>
  <div class="receipt_substraction px-5" :class="{ 'active': substractions.length > 0 }">
    <div class="row pt-3 pb-5">
      <div class="col-12 text-center substraction_heading">
        <i v-if="icon" :class="'icon-'+icon"></i><span class="h6">{{ label }}</span>
      </div>
      <div v-for="(s,i) in substractions" :key="'substraction_' + i" class="col-12 mt-3">
        <div class="input-group">
          <span class="input-group-text"><i class="icon-coins"></i></span>
          <input v-model="substractions[i]" type="number" min="0" step="0.01" class="form-control" placeholder="Kwota"/>
          <span class="input-group-text remove_substraction_button text-center" @click="remove(i)"><i class="icon-trash"></i></span>
        </div>
      </div>
    </div>
    <div class="add_button text-center" @click="add"><i class="icon-plus"></i></div>
  </div>
</template>

<script>
export default {
  name: "ReceiptSubstraction",
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      substractions: []
    }
  },
  computed: {
    sum() {
      return this.substractions.reduce((a,b) => {
        if(a === null) a = 0;
        if(b === null) b = 0;
        return parseFloat(a) + parseFloat(b)
      }, 0)
    }
  },
  watch: {
    sum: function(newValue) {
      this.$emit('substraction-sum-changed', newValue)
    }
  },
  methods: {
    add() {
      this.substractions.push(null)
      this.$emit('substraction-sum-changed', this.sum)
    },
    remove(i) {
      this.substractions.splice(i, 1)
      this.$emit('substraction-sum-changed', this.sum)
    }
  }
}
</script>

<style scoped lang="scss">
.receipt_substraction {
  position: relative;
  color: $gray-600;
  background: $gray-300;
  .input-group {
    i {
      font-size: 24px;
      color: $gray-500;
    }
  }
  &.active {
    color: $white;
    background: $danger;
    .input-group-text:first-child {
      border: none;
      background: $white;
    }
    input {
      border-color: $white;
    }
  }
  .substraction_heading {
    font-size: 1.5rem;
    i {
      display: inline-block;
      transform: translateY(3px);
    }
    i + span {
      margin-left: 0.5rem;
    }
  }
  .add_button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: -21px;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    display: inline-block;
    background: $white;
    border: 1px solid #EAEAEA;
    i {
      transform: translateY(8px);
      display: inline-block;
      font-size: 1.5rem;
      color: $black;
    }
  }
  .remove_substraction_button {
    position: absolute;
    background: $white;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    width: 37px;
    height: 37px;
    border: 3px solid $danger;
    padding: 4px;
    i {
      display: inline-block;
      font-size: 1.5rem;
      color: $black;
    }
  }
}
</style>