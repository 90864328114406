<template>
  <div class="select_customer_identification_method">
    <div class="container">
      <view-header title="Metoda identyfikacji" @back="$router.push('/')"></view-header>

      <div class="row row-cols-3 mt-5 justify-content-center">
        <div v-if="$store.state.config.customer_identification.email" class="col my-3">
          <tile-button @click="setIdentification('email')" label="Adres email" icon="mail"></tile-button>
        </div>
        <div v-if="$store.state.config.customer_identification.phone_number" class="col my-3">
          <tile-button @click="setIdentification('phone_number')" label="Numer telefonu" icon="phone"></tile-button>
        </div>
        <div v-if="$store.state.config.customer_identification.card_number"  class="col my-3">
          <tile-button label="Skanowanie karty" icon="scan-card"></tile-button>
        </div>
        <div v-if="$store.state.config.customer_identification.external_identifier"  class="col my-3">
          <tile-button @click="setIdentification('external_identifier')" :label="$store.state.config.customer_register_data.external_identifier.name" icon="feed"></tile-button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <router-link to="/" class="btn btn-primary">Powrót</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TileButton from "@/components/TileButton";
import ViewHeader from "@/components/ViewHeader";

export default {
  name: "SelectCustomerIdentificationMethod",
  components: {
    TileButton,
    ViewHeader
  },
  methods: {
    setIdentification(method) {
      this.$router.push({ name: 'CustomerIdentification', params: { method: method } })
    }
  }
}
</script>

<style scoped>

</style>