<template>
  <div class="current_user_info">
    <view-header title="Wybór nagrody" @back="$router.push('/customer-service')"></view-header>

    <div class="container">
      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs row" id="reward_tabs_nav">
            <li class="nav-item text-center col">
              <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#reward_issues_tab" type="button">Nagrody zarezerwowane</button>
            </li>
            <li class="nav-item text-center col">
              <button class="nav-link" data-bs-toggle="tab" data-bs-target="#rewards_tab" type="button">Nagrody dostępne</button>
            </li>
          </ul>
          <div class="tab-content mt-5" id="reward_tabs_content">
            <div class="tab-pane fade show active" id="reward_issues_tab">
              <div class="row">
                <div class="col col-6" v-for="rewardIssue in availableRewardIssues" :key="'reward_issue_'+rewardIssue.uuid">
                  <reward-tile :reward="rewardIssue.reward" @click="rewardIssueCollect(rewardIssue.uuid)"></reward-tile>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="rewards_tab">
              <div class="row">
                <div class="col-6" v-for="reward in availableRewards" :key="'available_reward_'+reward.uuid">
                  <reward-tile :reward="reward" @click="selectedReward = reward.uuid" :class="{ active: selectedReward === reward.uuid }"></reward-tile>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="col-12">
          <input :class="{'is-invalid': errors.reward}" type="hidden" class="form-control"/>
          <form-control-errors :error-bag="errors" field="reward"></form-control-errors>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-6 text-end">
          <router-link to="/customer-service" class="btn btn-light">Cofnij</router-link>
        </div>
        <div class="col-6">
          <button class="btn btn-primary" :disabled="!selectedReward" @click="handleRewardReservation">Dalej</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import RewardTile from "@/components/RewardTile";
import FormControlErrors from "@/components/FormControlErrors";
import api from '../../../api'
const bootstrap = require('bootstrap')

export default {
  name: "RewardSelect",
  components: {
    RewardTile,
    ViewHeader,
    FormControlErrors
  },
  data() {
    return {
      availableRewards: [],
      availableRewardIssues: [],
      selectedReward: null,
      errors: {},
    }
  },
  created() {
    this.loadAvailableRewards()
      .finally(() => {
        this.loadAvailableRewardIssues()
          .then(() => {
            // this.setActiveTab()
          })
      })
  },
  methods: {
    rewardIssueCollect(uuid) {
      this.$router.push('/reward-issue-receipt-check/' + uuid)
    },
    loadAvailableRewards() {
      return api.loadAvailableRewards(this.$store.state.currentUser.id)
        .then(response => { this.availableRewards = response.data })
    },
    loadAvailableRewardIssues() {
      return api.loadAvailableRewardIssues(this.$store.state.currentUser.id)
        .then(response => { this.availableRewardIssues = response.data })
    },
    setActiveTab() {
      let tab = new bootstrap.Tab(this.$refs.tab_nav.children[0]);
      this.$nextTick(() => { tab.show() })
    },
    handleRewardReservation() {
      api.reserveReward(this.selectedReward, this.$store.state.currentUser.id)
        .then(response => {
          this.$router.push('/reward-issue-receipt-check/' + response.data.uuid)
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.nav-tabs {
  border-bottom: none;
}
.nav-link {
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 12px 20px;
  border: none;
  &.active {
    border-bottom: 2px solid $primary;
  }
}
</style>