<template>
  <div v-if="errorBag[field]" class="invalid-feedback">
    <span v-for="(e,i) in errorBag[field]" :key="field+'_error_'+i">{{ e }}<br/></span>
  </div>
</template>

<script>
export default {
  name: "FormControlErrors",
  props: ['errorBag', 'field']
}
</script>

<style scoped>

</style>