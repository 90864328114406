<template>
  <div class="current_user_info">
    <view-header title="Weryfikacja danych klienta" @back="$router.push('/reward-select')"></view-header>

    <div class="container">
      <div class="row">
        <current-user-data></current-user-data>
      </div>

      <div class="row my-5">
        <div class="col text-center">
          <router-link :to="'/reward-issue-signature/'+$route.params.uuid" class="btn btn-primary">Dalej</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import CurrentUserData from "@/components/CurrentUserData";

export default {
  name: "RewardIssueReceiptCheck",
  components: {
    CurrentUserData,
    ViewHeader
  }
}
</script>

<style scoped>

</style>