<template>
  <div class="current_user_info">
    <view-header title="Informacje o kliencie" disableBack></view-header>

    <div class="container">
      <current-user-data></current-user-data>

      <div class="row mt-5">
        <div class="col text-center">
          <router-link to="/customer-service" class="btn btn-primary">Dalej</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import CurrentUserData from "@/components/CurrentUserData";
import api from "@/api";

export default {
  name: "CurrentUserInfo",
  components: {
    CurrentUserData,
    ViewHeader
  },
  mounted() {
    if(this.$route.params.refresh) {
      api.getCustomerById(this.$store.state.currentUser.id)
        .then(response => {
          this.$store.dispatch('setCurrentUser', response.data)
        })
    }
  }
}
</script>

<style scoped>

</style>