<template>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <span><strong>Imię:</strong> {{ $store.state.currentUser.first_name }}<br/></span>
        <span><strong>Nazwisko:</strong> {{ $store.state.currentUser.last_name }}<br/></span>
        <span><strong>Adres email:</strong> {{ $store.state.currentUser.email }}<br/></span>
        <span><strong>Kod pocztowy:</strong> {{ $store.state.currentUser.postal_code }}<br/></span>
        <span><strong>Posiadane punkty:</strong> {{ $store.state.currentUser.loyalty_points }}<br/></span>
        <span><strong>Liczba odebranych nagród:</strong> {{ $store.state.currentUser.collected_rewards_count }}<br/></span>
      </div>
    </div>

    <div v-if="$store.state.currentUser.tags.length > 0" class="row mt-3">
      <div class="col">
        <strong>Uwagi: </strong><br/>
        <span class="badge bg-danger" v-for="t in $store.state.currentUser.tags" :key="'tag_'+t.id">{{ t.name }}<br/></span>
      </div>
    </div>

    <div v-if="$store.state.currentUser.receipts.length > 0" class="row mt-3">
      <div class="col">
        <strong>Zarejestrowane paragony klienta: </strong>
        <table class="table table-bordered border-primary table-sm">
          <thead>
          <tr>
            <th>Sklep</th>
            <th>Wartość</th>
            <th>Data transakcji</th>
            <th>Numer paragonu</th>
            <th>Data rejestracji</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in $store.state.currentUser.receipts" :key="r.uuid">
            <td>{{ $store.getters.shopNameByUuid(r.shop_uuid) }}</td>
            <td>{{ (r.total_value / 100).toFixed(2) }}</td>
            <td>{{ r.transaction_date }}</td>
            <td>{{ r.receipt_number }}</td>
            <td>{{ new Date(r.created_at).toISOString().replace('T', ' ').replace('.000Z', '') }}</td>
            <td>
              <span v-if="r.status === 'accepted'" class="badge bg-success">Zaakceptowany</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrentUserData"
}
</script>

<style scoped>

</style>