<template>
  <div class="customer_service">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div class="row row-cols-3 justify-content-center">
        <div class="col my-3">
          <tile-button label="Wydanie nagrody" icon="prize" @click="rewardSelect"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Rejestracja paragonu" icon="add-receipt" @click="receiptList"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Informacje o użytkowniku" icon="user" @click="userInfo"></tile-button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h6>Aktualnie obsługiwany użytkownik: <strong>{{ $store.getters.currentUserDisplayName }}</strong></h6>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button class="btn btn-primary" @click="back">Powrót do ekranu głównego</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TileButton from "@/components/TileButton";
import Brand from "@/components/Brand";

export default {
  name: "CustomerService",
  components: {
    TileButton,
    Brand,
  },
  methods: {
    userInfo() {
      this.$router.push({ name: 'CurrentUserInfo', params: { refresh: true } })
    },
    receiptList() {
      this.$router.push('/receipt-list')
    },
    rewardSelect() {
      this.$router.push('/reward-select')
    },
    back() {
      this.$store.dispatch('clearCurrentUser')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>