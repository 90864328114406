<template>
  <div class="register_new">
    <view-header title="Nowy klient" @back="$router.push('/')"></view-header>

    <div class="container">
      <div class="row">
        <form class="col" @submit="next($event)">

          <div v-if="$store.state.config.customer_register_data.email.enabled" class="row mb-4">
            <div class="col">
              <input v-model="email" type="email" name="email" placeholder="Adres e-mail" class="form-control"
                     inputmode="email"
                     :class="{'is-invalid': formErrors.email}"
                     :required="$store.state.config.customer_register_data.email.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="email"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customer_register_data.phone_number.enabled" class="row mb-4">
            <div class="col">
              <input v-model="phone_number" type="text" name="phone_number" placeholder="Numer telefonu" class="form-control"
                     :class="{'is-invalid': formErrors.phone_number}"
                     inputmode="tel"
                     :required="$store.state.config.customer_register_data.phone_number.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="phone_number"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customer_register_data.first_name.enabled" class="row mb-4">
            <div class="col">
              <input v-model="first_name" type="text" name="first_name" placeholder="Imię" class="form-control"
                     :class="{'is-invalid': formErrors.first_name}"
                     :required="$store.state.config.customer_register_data.first_name.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="first_name"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customer_register_data.last_name.enabled" class="row mb-4">
            <div class="col">
              <input v-model="last_name" type="text" name="last_name" placeholder="Nazwisko" class="form-control"
                     :class="{'is-invalid': formErrors.last_name}"
                     :required="$store.state.config.customer_register_data.last_name.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="last_name"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customer_register_data.postal_code.enabled" class="row mb-4">
            <div class="col">
              <input v-model="postal_code" pattern="[0-9]{2}-[0-9]{3}" type="text" name="postal_code" placeholder="Kod pocztowy" class="form-control"
                     inputmode="numeric"
                     :class="{'is-invalid': formErrors.postal_code}"
                     :required="$store.state.config.customer_register_data.postal_code.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="postal_code"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customer_register_data.birthdate.enabled" class="row mb-5">
            <div class="col">
              <input v-model="birthdate" type="text" name="birthdate" placeholder="Data urodzenia" class="form-control"
                     :class="{'is-invalid': formErrors.birthdate}"
                     :required="$store.state.config.customer_register_data.birthdate.required" autocomplete="off"/>
              <form-control-errors :error-bag="formErrors" field="birthdate"></form-control-errors>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <button class="btn btn-primary" type="submit">Dalej</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import api from '../../api'
import FormControlErrors from "@/components/FormControlErrors";

export default {
  name: 'UserData',
  components: {FormControlErrors, ViewHeader},
  data() {
    return {
      email: null,
      phone_number: null,
      first_name: null,
      last_name: null,
      postal_code: null,
      birthdate: null,
      formErrors: {},
    }
  },
  methods: {
    next(e) {
      e.preventDefault()
      this.formErrors = {}
      api.validateUserData(this.getData())
        .then(() => {
          this.$store.commit('SET_REGISTER_USER_DATA', this.getData())
          this.$router.push('/register-new-agreements')
        })
        .catch((error) => {
          if(error.response.status === 422) {
            this.formErrors = error.response.data.errors
          }
        })
    },
    getData() {
      let data = {
        email: this.email,
        phone_number: this.phone_number,
        first_name: this.first_name,
        last_name: this.last_name,
        postal_code: this.postal_code,
        birthdate: this.birthdate,
      }
      for(var propName in data) {
        if(data[propName] === null || data[propName] === undefined) delete data[propName]
      }
      return data
    }
  }
}
</script>
