<template>
  <div class="customer_identification">
    <view-header title="Identyfikacja klienta" @back="$router.push('/')"></view-header>

    <div class="container">
      <div class="row">
        <div v-if="method === 'email'" class="col">
          <form class="row" @submit="handleEmailIdentification">
            <div class="col-12">
              <input v-model="email" placeholder="Adres E-mail" name="email" type="email" class="form-control" required autocomplete="off"/>
            </div>

            <div class="col-12 mt-3 text-center">
              <input :class="{'is-invalid': errors.customer}" type="hidden" class="form-control"/>
              <form-control-errors :error-bag="errors" field="customer"></form-control-errors>
            </div>

            <div class="col-12 mt-3 text-center">
              <button class="btn btn-primary">Dalej</button>
            </div>
          </form>
        </div>

        <div v-if="method === 'phone_number'" class="col">
          <form class="row" @submit="handlePhoneNumberIdentification">
            <div class="col-12">
              <input v-model="phone_number" placeholder="Numer telefonu" name="phone_number" type="text" inputmode="tel" class="form-control" required autocomplete="off"/>
            </div>

            <div class="col-12 mt-3 text-center">
              <input :class="{'is-invalid': errors.customer}" type="hidden" class="form-control"/>
              <form-control-errors :error-bag="errors" field="customer"></form-control-errors>
            </div>

            <div class="col-12 mt-3 text-center">
              <button class="btn btn-primary">Dalej</button>
            </div>
          </form>
        </div>

        <div v-if="method === 'external_identifier'" class="col">
          <form class="row" @submit="handleExternalIdentifierIdentification">
            <div class="col-12">
              <input v-model="external_identifier" :placeholder="$store.state.config.customer_register_data.external_identifier.name" name="external_identifier" type="text" class="form-control" required autocomplete="off"/>
            </div>

            <div class="col-12 mt-3 text-center">
              <input :class="{'is-invalid': errors.customer}" type="hidden" class="form-control"/>
              <form-control-errors :error-bag="errors" field="customer"></form-control-errors>
            </div>

            <div class="col-12 mt-3 text-center">
              <button class="btn btn-primary">Dalej</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import api from '@/api'
import FormControlErrors from "@/components/FormControlErrors";

export default {
  name: "CustomerIdentification",
  components: {FormControlErrors, ViewHeader},
  data() {
    return {
      email: null,
      external_identifier: null,
      phone_number: null,
      errors: {}
    }
  },
  computed: {
    method() {
      return this.$route.params.method
    }
  },
  methods: {
    handleEmailIdentification(e) {
      e.preventDefault()
      api.getCustomerByEmail(this.email)
        .then(response => {
          this.handleSuccess(response)
        })
        .catch(error => {
          this.handleErrors(error)
      })
    },
    handlePhoneNumberIdentification(e) {
      e.preventDefault()
      api.getCustomerByPhoneNumber(this.phone_number)
        .then(response => {
          this.handleSuccess(response)
        })
        .catch(error => {
          this.handleErrors(error)
        })
    },
    handleExternalIdentifierIdentification(e) {
      e.preventDefault()
      api.getCustomerByExternalIdentifier(this.external_identifier)
        .then(response => {
          this.handleSuccess(response)
        })
        .catch(error => {
          this.handleErrors(error)
        })
    },
    handleSuccess(response) {
      this.$store.dispatch('setCurrentUser', response.data)
      this.$router.push('/current-user-info')
    },
    handleErrors(error) {
      if(error.response.status === 404) {
        this.errors = { customer: ['Nie odnaleziono użytkownika'] }
      }
    }
  }
}
</script>

<style scoped>

</style>