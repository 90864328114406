<template>
  <div class="row">
    <div class="col-12">
      <receipt-substraction label="Alkohol" icon="beer" @substraction-sum-changed="updateSubstraction($event, 'alcohol')"></receipt-substraction>
    </div>
    <div class="col-12 mt-5">
      <receipt-substraction label="Wyroby tytoniowe" icon="tobacco" @substraction-sum-changed="updateSubstraction($event, 'tobacco')"></receipt-substraction>
    </div>
  </div>
</template>

<script>
import ReceiptSubstraction from "@/components/ReceiptSubstraction";

export default {
  name: "ReceiptSubstractionSet",
  components: {ReceiptSubstraction},
  data() {
    return {
      substractions: {
        alcohol: 0,
        tobacco: 0,
      }
    }
  },
  computed: {
    totalSubstraction() {
      return this.substractions.alcohol + this.substractions.tobacco
    }
  },
  watch: {
    totalSubstraction: function(newValue) {
      this.$emit('substraction-sum-changed', newValue)
    }
  },
  methods: {
    updateSubstraction(value, label) {
      this.substractions[label] = value
    }
  }
}
</script>

<style scoped>

</style>