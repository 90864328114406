import $store from './store'
import axios from "axios";

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function(config) {
    $store.commit('LOADING', true)
    return config
}, function(error) {
    return Promise.reject(error)
})
axios.interceptors.response.use(function(response) {
    $store.commit('LOADING', false)
    return response
}, function(error) {
    $store.commit('LOADING', false)
    return Promise.reject(error)
})

const API_URL = $store.state.config.api_url

export default {
    loginQr(qr) {
        return this.getCsrf()
            .then(() => {
                return axios.post(API_URL + 'sa/authorization-code-login', { authorizationCode: qr })
            })
    },
    logOut() {
        return axios.post(API_URL + 'sa/logout')
    },
    getCsrf() {
        return axios.get(API_URL + 'sanctum/csrf-cookie')
    },
    validateUserData(userData) {
        return axios.post(API_URL + 'sa/validate-user-personal-data', userData)
    },
    getRegisterAgreements() {
        return axios.get(API_URL + 'sa/register-agreements')
    },
    getReceiptRegisterAvailableShops() {
        return axios.get(API_URL + 'sa/shops')
    },
    getStaticContentPage(uuid) {
        return axios.get(API_URL + 'api/pages/' + uuid)
    },
    storeUser(userData) {
        return axios.post(API_URL + 'sa/users/', userData)
    },
    getCustomerById(id) {
        return axios.get(API_URL + 'sa/users/' + id)
    },
    getCustomerByEmail(email) {
        return axios.get(API_URL + 'sa/users/get-by-email/' + email)
    },
    getCustomerByPhoneNumber(phone_number) {
        return axios.get(API_URL + 'sa/users/get-by-phone-number/' + phone_number)
    },
    getCustomerByExternalIdentifier(eid) {
        return axios.get(API_URL + 'sa/users/get-by-external-identifier/' + eid)
    },
    uploadReceiptImage(userId, receiptImageData) {
        return axios.post(API_URL + 'sa/add-receipt', {
            user_id: userId,
            image_data: receiptImageData
        })
    },
    storeReceipt(receiptData) {
        receiptData.total_value = parseFloat(receiptData.total_value)
        return axios.post(API_URL + 'sa/receipt/' + receiptData.uuid + '/accept', receiptData)
    },
    getReceipt(uuid) {
        return axios.get(API_URL + 'sa/receipt/' + uuid)
    },
    loadAvailableRewards(userId) {
        return axios.get(API_URL + 'sa/rewards/' + userId)
    },
    loadAvailableRewardIssues(userId) {
        return axios.get(API_URL + 'sa/reward-issues/' + userId)
    },
    reserveReward(rewardId, userId) {
        return axios.post(API_URL + 'sa/reward-issues/' + userId, {
            reward_uuid: rewardId
        })
    },
    saveRewardIssueCollectSignature(rewardIssueId, signatureData) {
        return axios.post(API_URL + 'sa/reward-issues/' + rewardIssueId + '/signature', {
            signature: signatureData
        })
    }
}