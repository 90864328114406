<template>
  <div :class="{ loading: $store.state.loading }">
    <div ref="loadingModal" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="spinner-border text-danger m-5" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p v-if="$store.state.loadingInfo">{{ $store.state.loadingInfo }}</p>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
const bootstrap = require('bootstrap')

export default {
  name: 'App',
  data() {
    return {
      loadingModal: null,
    }
  },
  mounted() {
    this.loadingModal = new bootstrap.Modal(this.$refs.loadingModal)
  },
  watch: {
    '$store.state.loading': function(newValue) {
      if(newValue) this.loadingModal.show()
      else this.loadingModal.hide()
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./assets/icomoon/style.css";
@import "./node_modules/bootstrap/scss/bootstrap";

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}

.btn {
  letter-spacing: 0.1em;
}
.btn-primary {
  color: $white;
  &:hover {
    color: $white;
  }
}
</style>
