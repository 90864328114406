export default {
    api_url: process.env.VUE_APP_API_URL,
    features: {
        customer_register: true,
        customer_service: true,
        receipt_register: true,
        reward_issue: true,
    },
    customer_register_data: {
        email: {
            enabled: true,
            required: true,
        },
        phone_number: {
            enabled: true,
            required: false,
        },
        first_name: {
            enabled: true,
            required: true,
        },
        last_name: {
            enabled: true,
            required: true,
        },
        birthdate: {
            enabled: false,
            required: false,
        },
        postal_code: {
            enabled: true,
            required: false,
        },
    },
    customer_register_additional_data: [],
    customer_identification: {
        email: true,
        phone_number: true,
        card_number: false,
    },
    receipt_scanner: true,
    static_content_pages: [
        {
            name: 'Regulamin',
            uuid: '719b35c2-2c26-463d-b022-b2fc409c938d',
        }
    ],
    register_new_user_agreement_view_static_content_pages: [
        {
            name: 'Regulamin',
            uuid: '719b35c2-2c26-463d-b022-b2fc409c938d',
        }
    ]
}