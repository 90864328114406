<template>
  <div class="static_content_page">
    <view-header :title="title" @back="$router.back()"></view-header>

    <div class="container">
      <div class="row my-5">
        <div v-if="page" class="col" v-html="page.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";

export default {
  name: "StaticContentPage",
  components: {
    ViewHeader
  },
  computed: {
    title() {
      return this.$store.state.config.static_content_pages.find(p => p.uuid === this.$route.params.uuid).name
    },
    page() {
      return this.$store.state.staticContentPages.find(p => p.uuid === this.$route.params.uuid)
    }
  },
  beforeCreate() {
    this.$store.dispatch('loadStaticContentPage', this.$route.params.uuid)
  }
}
</script>

<style scoped>

</style>