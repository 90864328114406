<template>
  <div class="reward_tile" @click="$emit('click', $event)">
    <div class="reward_tile_container">
      <img :src="reward.thumbnail"/>
      <div v-if="!hide_description" class="overlay"></div>
      <div v-if="!hide_description" class="reward_tile_description">
        <div class="reward_price">
          <span>{{ reward.price }}</span><small>pkt</small>
        </div>
      </div>
    </div>
    <h3 class="mt-3">{{ reward.name }}</h3>
  </div>
</template>

<script>
export default {
  name: "RewardTile",
  props: {
    reward: {
      type: Object,
      required: true
    },
    hide_description: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.reward_tile {
  cursor: pointer;
  
  &.active {
    .overlay {
      display: none;
    }
    .reward_tile_container {
      padding: 12px;
      border: 3px solid $primary;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .reward_tile_container {
    display: flex;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, rgba(17, 17, 19, 0) 0%, rgba(17, 17, 19, 0.8) 100%);
    }

    .reward_tile_description {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $white;

      .reward_price {
        padding: 1rem;
        span {
          font-size: 36px;
          font-weight: 900;
          line-height: 43px;
          margin-right: 0.25rem;
        }
        small {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}
</style>