<template>
  <div class="receipt_list">
    <view-header title="Paragony" @back="back"></view-header>

    <div class="container">
      <div class="row mt-5">
        <div v-for="r in $store.state.receiptRegister.currentSessionReceipts" :key="'receipt_' + r.uuid" class="col-12 mb-3 border-bottom border-dark receipt_container">
          <div class="row">
            <div class="col-3">
              <img :src="r.preview"/>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <small class="text-uppercase">Wartość:</small><br/>
                  <h4>{{ (r.total_value / 100).toFixed(2) }} zł</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Data dodania:</small><br/>
                  <h4>{{ r.created_at }}</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Numer:</small><br/>
                  <h4>{{ r.receipt_number }}</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Sklep:</small><br/>
                  <h4>{{ $store.getters.shopNameByUuid(r.shop_uuid) }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <router-link v-if="$store.state.config.receipt_scanner" to="receipt-scan" class="btn btn-light">Dodaj paragon</router-link>
          <router-link v-else to="receipt-correction" class="btn btn-light">Dodaj paragon</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";

export default {
  name: "ReceiptList",
  components: {
    ViewHeader,
  },
  methods: {
    back() {
      this.$router.push('/customer-service')
    },
  }
}
</script>

<style scoped lang="scss">
.receipt_container {
  small {
    color: $gray-500;
    font-weight: 900;
    font-size: 12px;
  }
  h4 {
    font-size: 1rem;
  }
}
</style>