import { createStore } from 'vuex'
import config from '../app.config'
import api from '../api'

export default createStore({
  state: {
    loading: false,
    loadingInfo: null,
    config: config,
    currentUser: null,
    register: {
      userData: null,
      userAgreements: null,
      availableAgreements: [],
    },
    receiptRegister: {
      availableShops: [],
      currentSessionReceipts: [],
    },
    staticContentPages: [],
    loggedIn: false,
    loggedUser: null
  },
  getters: {
    customerIdentificationMethods: state => {
      let customerIdentificationMethods = [];
      //TODO: Refaktoryzacja tego i zrobienie po jakiejś pętli, bo aż boli jak się patrzy
      if(state.config.customer_identification.email) customerIdentificationMethods.push('email')
      if(state.config.customer_identification.phone_number) customerIdentificationMethods.push('phone_number')
      if(state.config.customer_identification.card_number) customerIdentificationMethods.push('card_number')
      if(state.config.customer_identification.external_identifier) customerIdentificationMethods.push('external_identifier')

      return customerIdentificationMethods
    },
    shopNameByUuid: state => uuid => {
      let shop = state.receiptRegister.availableShops.find(s => s.uuid === uuid)
      return shop ? shop.name : uuid;
    },
    featureAvailableCustomerService: (state, getters) => {
      return state.config.features.customer_service && getters.customerIdentificationMethods.length > 0
    },
    featureAvailableCustomerRegister: (state) => {
      return state.config.features.customer_register
    },
    staticPages: (state) => {
      return state.config.static_content_pages
    },
    currentUserDisplayName: (state) => {
      if(!state.currentUser) return null
      return state.currentUser.first_name + ' ' + state.currentUser.last_name
    }
  },
  mutations: {
    LOADING(state, loading) {
      if(typeof loading === 'string') {
        state.loadingInfo = loading
        loading = true;
      }
      state.loading = loading
      state.loadingInfo = null
    },
    SET_USER(state, user) {
      state.loggedUser = user
      state.loggedIn = user === null ? false : true;
    },
    SET_REGISTER_AGREEMENTS(state, agreements) {
      state.register.availableAgreements = agreements
    },
    SET_RECEIPT_REGISTER_AVAILABLE_SHOPS(state, shops) {
      state.receiptRegister.availableShops = shops
    },
    SET_REGISTER_USER_DATA(state, userData) {
      state.register.userData = userData
    },
    SET_REGISTER_USER_AGREEMENTS(state, agreements) {
      state.register.userAgreements = agreements
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user
    },
    ADD_STATIC_CONTENT_PAGE(state, page) {
      state.staticContentPages.push(page)
    },
    ADD_RECEIPT_TO_CURRENT_SESSION(state, receipt) {
      state.receiptRegister.currentSessionReceipts.push(receipt)
    },
    CLEAR_CURRENT_SESSION_RECEIPT(state) {
      state.receiptRegister.currentSessionReceipts = []
    }
  },
  actions: {
    setLoggedIn({ commit }, user) {
      commit('SET_USER', user)
    },
    setLoggedOut({ commit }) {
      commit('SET_USER', null)
    },
    loadRegisterAgreements({ commit, state }) {
      if(state.register.availableAgreements.length > 0) return
      api.getRegisterAgreements()
        .then((response) => {
          commit('SET_REGISTER_AGREEMENTS', response.data)
        })
    },
    loadReceiptRegisterAvailableShops({ commit, state }) {
      if(state.receiptRegister.availableShops.length > 0) return
      api.getReceiptRegisterAvailableShops()
        .then(response => {
          commit('SET_RECEIPT_REGISTER_AVAILABLE_SHOPS', response.data)
        })
    },
    loadStaticContentPage({ commit, state }, uuid) {
      if(state.staticContentPages.find(p => p.uuid === uuid)) return
      api.getStaticContentPage(uuid)
        .then(response => {
          commit('ADD_STATIC_CONTENT_PAGE', response.data)
        })
    },
    addReceiptToCurrentSession({ commit }, receipt) {
      commit('ADD_RECEIPT_TO_CURRENT_SESSION', receipt)
    },
    clearRegisterData({ commit }) {
      commit('SET_REGISTER_USER_DATA', null)
      commit('SET_REGISTER_USER_AGREEMENTS', null)
    },
    setCurrentUser({ commit }, user) {
      commit('SET_CURRENT_USER', user)
    },
    clearCurrentUser({ commit }) {
      commit('SET_CURRENT_USER', null)
      commit('CLEAR_CURRENT_SESSION_RECEIPT')
    }
  },
  modules: {

  }
})
