<template>
  <div class="view_header mb-5">
    <div class="container">
      <div class="row mt-5 mb-4">
        <div class="col">
          <div class="row mb-4">
            <div class="col text-center">
              <brand></brand>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-2">
              <i v-if="!disableBack" class="icon-angle-left" @click="$emit('back')"></i>
            </div>

            <div class="col-8 text-center">
              <h2>{{ title }}</h2>
            </div>

            <div class="col-2">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand";
export default {
  name: "ViewHeader",
  components: {Brand},
  props: {
    title: {
      type: String,
      required: true
    },
    disableBack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.view_header {
  width: 100%;
  border-bottom: 1px solid $gray-300;
}
h2 {
  font-size: 28px;
  font-weight: 900;
  line-height: 34px;
  letter-spacing: 0;
}
i {
  cursor: pointer;
  font-size: 1.5rem;
}
</style>