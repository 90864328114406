<template>
  <div class="home">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div class="row row-cols-3 justify-content-center">
        <div v-if="$store.getters.featureAvailableCustomerRegister" class="col my-3">
          <tile-button label="Nowy klient" icon="new-user" @click="registerNew"></tile-button>
        </div>
        <div v-if="$store.getters.featureAvailableCustomerService" class="col my-3">
          <tile-button label="Obsługa klienta" icon="return-user" @click="customerService"></tile-button>
        </div>
        <div v-for="(page, index) in $store.getters.staticPages" :key="'static_page_'+index" class="col my-3">
          <tile-button :label="page.name" icon="document" @click="staticPage(page.uuid)"></tile-button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 text-center">
          Zalogowany jako: <strong>{{ $store.state.loggedUser.name }}</strong>
        </div>
        <div class="col text-center mt-3">
          <button class="btn btn-light" @click="logOut">Wyloguj</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TileButton from "@/components/TileButton";
import api from '../api'
import Brand from "@/components/Brand";

export default {
  name: 'Home',
  components: {
    Brand,
    TileButton,
  },
  created() {
    this.$store.dispatch('loadReceiptRegisterAvailableShops')
  },
  methods: {
    registerNew() {
      this.$router.push('register-new-user-data')
    },
    customerService() {
      let cim = this.$store.getters.customerIdentificationMethods
      if(cim.length > 1) {
        this.$router.push('select-customer-identification-method')
      } else {
        this.$router.push({ name: 'CustomerIdentification', params: { method: cim[0] } })
      }
    },
    staticPage(id) {
      this.$router.push('/static-content-page/'+id)
    },
    logOut() {
      api.logOut()
        .finally(() => {
          this.$store.dispatch('setLoggedOut')
          this.$router.push('/authorization-code-scan')
        })
    }
  }
}
</script>
